<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';

import {
  toast,
  WsSelect,
  WsSelectOption,
  WsButton,
  ToastStatus,
} from '@mfl/common-components';
import {
  manageTemplateApiSdk,
  renderApiSdk,
} from '@msl/signature-editor-gateway-sdk';

import { elementBoxes } from './side-panel-utils';
import { handleDragStart } from '../../shared/event-handlers';
import { Signature, EditorViewMode } from '../../layout-editor-types';
import { currentTab } from '../../shared/reactives';
import strings from '../../layout-editor.strings';
import { focusedElement, style, data } from '../../shared/reactives';
import { setHtmlAndReattachEvents } from '../layout-preview/element-events';
import { ElementTag } from '../../shared/types';
import { openSignatureNameDialog } from '../signature-name-dialog/signature-name-dialog';

import ElementEditor from '../element-editor/element-editor.vue';
import { saveSignature } from '../../shared/db';

const signaturOptions = ref<Signature[]>([]);
const selectedSignature = ref<Signature>({
  id: '',
  name: '',
});
const renderByIdInProgress = ref(false);

// Get current element's IDs and data/style
const currentStyleId = computed(() => {
  return (
    focusedElement.value?.parentElement?.getAttribute('element-style-id') || ''
  );
});
const currentDataId = computed(() => {
  return (
    focusedElement.value?.parentElement?.getAttribute('element-data-id') || ''
  );
});

const selectedElementType = computed<ElementTag | null>(() => {
  if (!focusedElement.value?.parentElement) return null;
  return (
    (focusedElement.value?.parentElement?.getAttribute(
      'element-ws-tag'
    ) as unknown as ElementTag) || null
  );
});

onMounted(async () => {
  const { signatures } = await manageTemplateApiSdk.listSignatures({});
  signaturOptions.value =
    signatures?.map((signature) => ({
      id: signature.id ?? '',
      name: signature.name ?? '',
    })) ?? [];

  const textItem = document.getElementById('textItem');
  const imageItem = document.getElementById('imageItem');

  setHtmlAndReattachEvents('');

  if (textItem) {
    textItem.addEventListener('dragstart', handleDragStart);
  }

  if (imageItem) {
    imageItem.addEventListener('dragstart', handleDragStart);
  }
});

watch(selectedSignature, async () => {
  if (!selectedSignature.value) {
    style.value = {};
    data.value = {};

    setHtmlAndReattachEvents('');
    return;
  }

  renderByIdInProgress.value = true;
  try {
    const {
      html,
      style: newStyle,
      data: newData,
    } = await renderApiSdk.renderById({
      id: selectedSignature.value.id,
      payload: {},
    });

    style.value = newStyle ?? {};
    data.value = newData ?? {};
    setHtmlAndReattachEvents(html ?? '');
  } catch (error) {
    console.error(`Failed to render signature: ${error}`);
  } finally {
    renderByIdInProgress.value = false;
  }
});

async function save() {
  let signatureName = '';
  if (!selectedSignature.value?.id) {
    const dialogRes = await openSignatureNameDialog('');
    signatureName = dialogRes.name;
  } else {
    signatureName = selectedSignature.value.name;
  }

  if (signatureName !== '') {
    const res = await saveSignature(signatureName, selectedSignature.value?.id);
    if (res === ToastStatus.Completed) {
      toast({
        message: `${signatureName} was saved successfully`,
        status: ToastStatus.Completed,
        aid: 'LAYOUT_EDITOR_SAVE_SIGNATURE_SUCCESS',
      });
    } else {
      toast({
        message: 'Error while saving signature',
        status: ToastStatus.Error,
        aid: 'LAYOUT_EDITOR_SAVE_SIGNATURE_ERROR',
      });
    }
  }
}

function handleUpdateSignature(signature: Signature) {
  if (signature) {
    selectedSignature.value = signature;
  } else {
    selectedSignature.value = {
      id: '',
      name: '',
    };
  }
}
</script>

<template>
  <div class="side-panel">
    <div
      v-show="currentTab.value === EditorViewMode.ELEMENTS"
      class="side-panel-elements"
    >
      <span class="header">{{ strings.header }}</span>
      <div class="side-panel-elements-palette">
        <div class="side-panel-elements-palette-header">
          <span class="palette-header">{{ strings.headerPaletteBasic }}</span>
          <div class="side-panel-elements-palette-display">
            <div
              v-for="box in elementBoxes"
              :id="box.id"
              :key="box.id"
              draggable="true"
              class="side-panel-elements-palette-display-box draggable"
            >
              <div><i :class="box.icon" class="icon" /></div>
              <div>
                <span class="label">{{ box.label }}</span>
              </div>
              <div><i class="fa-grip-dots fa-regular grip" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="currentTab.value === EditorViewMode.EDIT">
      <ElementEditor
        v-model:style="style[currentStyleId]"
        v-model:data="data[currentDataId]"
        :element="focusedElement?.value"
        :element-type="selectedElementType"
      />
    </div>
    <div class="side-panel-controls">
      <WsSelect
        size="md"
        :option-key="(val: Signature) => val.id"
        :option-label="(val: Signature) => val.name"
        :model-value="selectedSignature"
        aid="LAYOUT_EDITOR_SIGNATURE"
        @update:model-value="
          (signature: unknown) => handleUpdateSignature(signature as Signature)
        "
      >
        <WsSelectOption
          v-for="option in signaturOptions"
          :key="option.id"
          :value="option"
        >
          {{ option.name }}
        </WsSelectOption>
      </WsSelect>
      <WsButton
        size="md"
        label="Save"
        aid="LAYOUT_EDITOR_SAVE_SIGNATURE"
        @click="save"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.side-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  border-right: 1px solid rgb(var(--color-gray-100));

  /* Draggable items */
  .draggable {
    display: flex;
    justify-content: center;
    border: 1px solid rgb(var(--color-gray-100));
    background: white;
    cursor: grab;
    height: 100px;
  }

  &-controls {
    padding: 20px;
  }

  &-elements {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 14px 0;

    .header {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: var(--color-gray-500);
      padding: 0 24px;
    }

    &-palette {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-top: 1px solid rgb(var(--color-gray-200));
      padding: 0 24px;
      height: 100%;

      .palette-header {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
        color: rgb(var(--color-gray-500));
      }

      &-display {
        display: grid;
        grid-template-columns: repeat(3, 118px);
        gap: 9px;

        &-box {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            height: 20px;
          }

          .label {
            font-size: 12px;
            font-weight: 500;
            color: rgb(var(--color-gray-500));
          }

          .grip {
            height: 5px;
            width: 10px;
          }
        }
      }
    }

    &-trash {
      width: 130px;
      padding: 10px;
      text-align: center;
      background: #fdd;
      font-weight: bold;
    }
  }
}
</style>
