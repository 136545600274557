import { openDialog } from '@mfl/common-components';

import strings from '../../layout-editor.strings';
import SignatureNameDialog from './signature-name-dialog.vue';

export type SignatureNameDialogResult = { name: string };

export async function openSignatureNameDialog(
  value: string
): Promise<SignatureNameDialogResult> {
  const res = await openDialog<string, SignatureNameDialogResult>({
    aid: 'SIGNATURE_NAME_DIALOG',
    headerText: strings.signatureNameModalTitle,
    component: SignatureNameDialog,
    input: value,
    showTopSeparator: false,
  });

  if (res === undefined) return { name: '' };

  return res;
}
