<script setup lang="ts">
import { ref } from 'vue';

import {
  IDialog,
  WsDialogForm,
  WsDialogSection,
  WsInput,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

const { dialog } = defineProps<{
  dialog: IDialog<string>;
}>();

const busy = ref(false);
const signatureName = ref<string>(dialog.input || '');
const error = ref<string>('');

async function save() {
  if (signatureName.value.trim() === '') return;

  busy.value = true;

  try {
    dialog.close({ name: signatureName.value.trim() });
  } catch {
    error.value = 'Saving signature failed';
  } finally {
    busy.value = false;
  }
}

function closeMe() {
  dialog.close();
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="rename-campaign-dialog-section">
      <WsInput
        v-model="signatureName"
        placeholder="Set your signature name"
        aid="SIGNATURE_NAME_INPUT"
        size="md"
        :error="error"
        autofocus
      />
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="SIGNATURE_NAME_CANCEL"
        variant="outlined"
        color="gray-500"
        @click="closeMe"
      >
        Cancel
      </WsButton>
      <WsButton aid="SIGNATURE_NAME_SAVE" :loading="busy" @click="save">
        Save
      </WsButton>
    </WsDialogActions>
  </WsDialogForm>
</template>

<style lang="scss">
.rename-campaign-dialog-section {
  width: 400px;
}
</style>
