<script setup lang="ts">
import { ref, computed } from 'vue';
import { defineModel } from 'vue';

import { WsInput, WsSlider } from '@mfl/common-components';

import { ElementTag } from '../../shared/types';

const FONT_SIZE = {
  MIN: 5,
  MAX: 42,
  DEFAULT: 12,
} as const;

const IMAGE_HEIGHT = {
  MIN: 5,
  MAX: 500,
  DEFAULT: 50,
} as const;

const IMAGE_WIDTH = {
  MIN: 5,
  MAX: 500,
  DEFAULT: 50,
} as const;

interface Props {
  element: HTMLElement | null;
  elementType: ElementTag | null;
}

defineProps<Props>();

const data = defineModel<Record<string, unknown>>('data');
const style = defineModel<Record<string, unknown>>('style');

const activeTab = ref<'content' | 'design'>('content');

const fontSize = computed({
  get: () => {
    return style.value?.['font-size']
      ? parseInt((style.value['font-size'] as string).replace('px', ''))
      : FONT_SIZE.DEFAULT;
  },
  set: (value: number) => {
    style.value = { ...style.value, 'font-size': `${value}px` };
  },
});

const contentValue = computed({
  get: () => {
    return data.value?.value as string;
  },
  set: (value: string) => {
    data.value = { ...data.value, value };
  },
});

const imageSourceValue = computed({
  get: () => {
    return data.value?.source as string;
  },
  set: (source: string) => {
    data.value = { ...data.value, source };
  },
});

const imageHeight = computed({
  get: () => {
    return style.value?.height
      ? parseInt((style.value['height'] as string).replace('px', ''))
      : IMAGE_HEIGHT.DEFAULT;
  },
  set: (value: number) => {
    style.value = { ...style.value, height: `${value}px` };
  },
});

const imageWidth = computed({
  get: () => {
    return style.value?.width
      ? parseInt((style.value['width'] as string).replace('px', ''))
      : IMAGE_WIDTH.DEFAULT;
  },
  set: (value: number) => {
    style.value = { ...style.value, width: `${value}px` };
  },
});
</script>

<template>
  <div class="element-editor">
    <div class="element-editor-tabs">
      <button
        :class="{ active: activeTab === 'content' }"
        @click="activeTab = 'content'"
      >
        Content
      </button>
      <button
        :class="{ active: activeTab === 'design' }"
        @click="activeTab = 'design'"
      >
        Design
      </button>
    </div>

    <div v-if="element" class="element-editor-content">
      <!-- Content Tab -->
      <div v-show="activeTab === 'content'">
        <template v-if="elementType === ElementTag.TEXT">
          <span class="header">Text</span>
          <ws-input v-model="contentValue" :aid="`TEXT_VALUE`" />
        </template>

        <template v-if="elementType === ElementTag.IMAGE">
          <span class="header">Image Source</span>
          <ws-input v-model="imageSourceValue" :aid="`IMAGE_SOURCE`" />
        </template>
      </div>

      <!-- Design Tab -->
      <div v-show="activeTab === 'design'">
        <template v-if="elementType === ElementTag.TEXT">
          <span class="header">Font Size</span>
          <ws-slider
            v-model="fontSize"
            label
            :aid="`TEXT_FONT_SIZE`"
            :min="FONT_SIZE.MIN"
            :max="FONT_SIZE.MAX"
          />
        </template>

        <template v-if="elementType === ElementTag.IMAGE">
          <span class="header">Image Height</span>
          <ws-slider
            v-model="imageHeight"
            label
            :aid="`IMAGE_HEIGHT`"
            :min="IMAGE_HEIGHT.MIN"
            :max="IMAGE_HEIGHT.MAX"
          />
          <span class="header">Image Width</span>
          <ws-slider
            v-model="imageWidth"
            label
            :aid="`IMAGE_WIDTH`"
            :min="IMAGE_WIDTH.MIN"
            :max="IMAGE_WIDTH.MAX"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.element-editor {
  &-tabs {
    display: flex;
    gap: 1px;
    margin-bottom: 16px;

    button {
      flex: 1;
      padding: 8px;
      border: none;
      background: rgb(var(--color-gray-100));
      cursor: pointer;

      &.active {
        background: rgb(var(--color-primary));
        color: white;
      }
    }
  }

  &-content {
    padding: 0 24px;

    .header {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: var(--color-gray-500);
      margin-bottom: 8px;
      display: block;
    }
  }
}
</style>
