import { DragData } from './types';
import { elementBoxes } from '../components/side-panel/side-panel-utils';
import { EditorDragSource } from './types';
import {
  addChildAttributes,
  addElementBorder,
  removeHoverBorders,
} from '../components/layout-preview/element-utils';
import {
  DROP_BOTTOM,
  DROP_LEFT,
  DROP_RIGHT,
  DROP_TOP,
  threshold,
} from '../components/layout-preview/layout-preview-consts';
import { createDroppedElementFromHTML } from '../components/layout-preview/drag-utils';
import {
  generateRowElement,
  generateColumnElement,
} from '../components/layout-preview/element-generators';

/**
 * Handles dragstart for both sidebar and table elements.
 * It sets a JSON string on the drag event with the source and type/id.
 */
export function handleDragStart(e: DragEvent): void {
  const target = e.target as HTMLElement;
  if (!target || !e.dataTransfer) return;

  let data: DragData;
  // For sidebar elements, store the element's HTML.
  const isSidebarElement =
    !target.dataset.source || target.dataset.source === 'sidebar';

  if (isSidebarElement) {
    const currentBox = elementBoxes.find((box) => box.id === target.id);
    data = {
      source: EditorDragSource.SIDEBAR,
      html: currentBox?.html,
      tag: currentBox?.tag,
      id: currentBox?.id ?? '',
    };
  } else {
    data = {
      source: EditorDragSource.TABLE,
      id: target.parentElement?.getAttribute('element-id') ?? '',
    };
  }
  e.dataTransfer.setData('application/json', JSON.stringify(data));
}

export function handleCellDragLeave(e: DragEvent): void {
  const currentTarget = e.currentTarget as HTMLElement | null;
  if (!e.dataTransfer || !currentTarget) return;

  removeHoverBorders(currentTarget);
}

/**
 * When hovering over a table cell, check if the pointer is near the right edge.
 * If so, add a class to highlight that border.
 * for only the first cell check also for the left
 */
export function handleCellDragOver(e: DragEvent): void {
  const currentTarget = e.currentTarget as HTMLElement | null;
  if (!e.dataTransfer || !currentTarget) return;

  e.preventDefault();
  const rect = currentTarget.getBoundingClientRect();
  const offsetX = e.clientX - rect.left;
  if (offsetX < threshold && currentTarget.matches(':first-child')) {
    addElementBorder(currentTarget, DROP_LEFT);
    return;
  }
  if (offsetX > rect.width - threshold) {
    addElementBorder(currentTarget, DROP_RIGHT);
    return;
  }

  const offsetY = e.clientY - rect.top;
  if (offsetY < threshold && currentTarget.matches(':first-child')) {
    addElementBorder(currentTarget, DROP_TOP);
    return;
  }
  if (offsetY > rect.height - threshold) {
    addElementBorder(currentTarget, DROP_BOTTOM);
  }
}

/**
 * - GOING TO BE REMOVED
 * keeping it for now for future row handling, it will be implemented together with the cell drop
 */
export function handleRowDrop(e: DragEvent): void {
  if (!e.dataTransfer || !e.currentTarget) return;
  const row = e.currentTarget as HTMLElement;
  if (row.children.length === 0) return;

  e.preventDefault();
  removeHoverBorders(row.children[0] as HTMLElement);
  const data = JSON.parse(e.dataTransfer.getData('application/json'));
  const rect = row.getBoundingClientRect();
  const offsetY = e.clientY - rect.top;
  const parent = row.parentElement;
  if (parent && (offsetY < threshold || offsetY > rect.height - threshold)) {
    // Create a new td and attach necessary event listeners.
    const newTr = generateRowElement();
    const newCell = generateColumnElement();
    newTr.appendChild(newCell);
    const newEl = createDroppedElementFromHTML(data.html);
    newCell.appendChild(newEl);
    addChildAttributes(newEl);

    if (offsetY < threshold) {
      parent.insertBefore(newTr, row);
    } else {
      if (row.nextSibling) {
        parent.insertBefore(newTr, row.nextSibling);
      } else {
        parent.appendChild(newTr);
      }
    }

    e.stopPropagation();
  }
}

/**
 * Handles drop events on a table cell.
 * - For sidebar drags: if dropped near an edge, a new cell is inserted with the new element.
 * - For table drags: if the cell is empty, the element is moved; if not, a swap is performed.
 */
export function handleCellDrop(e: DragEvent): void {
  const cell = e.currentTarget as HTMLElement;
  if (!e.dataTransfer || !cell) return;

  e.preventDefault();
  removeHoverBorders(cell);
  const data = JSON.parse(e.dataTransfer.getData('application/json'));
  const rect = cell.getBoundingClientRect();
  const offsetX = e.clientX - rect.left;
  const tr = cell.parentElement; // current table row
  // Check if the drop occurred near the left or right edge of the cell.
  if (tr && (offsetX < threshold || offsetX > rect.width - threshold)) {
    let newCell;
    let newEl;
    if (data.source === EditorDragSource.SIDEBAR) {
      // Create a new element by cloning the dragged element's HTML.
      newCell = generateColumnElement(data.tag);
      newEl = createDroppedElementFromHTML(data.html);
    } else if (data.source === EditorDragSource.TABLE) {
      // For repositioning, grab the existing element and remove it from its current cell.
      const sourceCell = document.querySelector(
        `[element-id="${data.id}"]`
      ) as HTMLElement;
      if (sourceCell && sourceCell.children.length > 0) {
        newEl = sourceCell.children[0] as HTMLElement;
        sourceCell.removeChild(newEl);
        newCell = sourceCell;
        sourceCell.remove();
      }
    }

    if (newCell && newEl) {
      newCell.appendChild(newEl);
      addChildAttributes(newEl);

      const insertPosition = offsetX < threshold ? cell : cell.nextSibling;
      tr.insertBefore(newCell, insertPosition || null);

      e.stopPropagation();
    }
    return; // Drop handled.
  }
}
