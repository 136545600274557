<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue';

import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';

import SidePanel from './components/side-panel/side-panel.vue';
import LayoutPreview from './components/layout-preview/layout-preview.vue';

let oldMode: NavigationBarMode | undefined;

onBeforeMount(() => {
  oldMode = navigationBarMode.value;
  navigationBarMode.value = NavigationBarMode.Hidden;
});

onUnmounted(() => {
  if (oldMode) navigationBarMode.value = oldMode;
});
</script>

<template>
  <div class="layout-editor">
    <!-- Side panel with two draggable source items -->
    <side-panel />
    <layout-preview />
  </div>
</template>

<style scoped lang="scss">
.layout-editor {
  display: flex;
  height: 100vh;
}
</style>
