import { reactive } from 'vue';

import { EditorViewMode } from '../layout-editor-types';

export const focusedElement = reactive<{ value: HTMLElement | null }>({
  value: null,
});

export const currentTab = reactive<{ value: EditorViewMode }>({
  value: EditorViewMode.ELEMENTS,
});

export const style = reactive<Record<string, Record<string, unknown>>>({});
export const data = reactive<Record<string, Record<string, unknown>>>({});
