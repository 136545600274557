import { generateRandomUUID, attachDragEvents } from './drag-utils';
import {
  handleCellDragOver,
  handleRowDrop,
  handleCellDrop,
  handleCellDragLeave,
} from '../../shared/event-handlers';

export function generateTableElement(): HTMLElement {
  const table = document.createElement('table');
  table.setAttribute('id', generateRandomUUID());
  table.setAttribute('ws-tag', 'LAYOUT');
  return table;
}

export function generateRowElement(): HTMLElement {
  const row = document.createElement('tr');
  row.setAttribute('id', generateRandomUUID());
  row.setAttribute('ws-tag', 'ROW');

  attachDragEvents(row, { drop: handleRowDrop });
  return row;
}

export function generateColumnElement(childTag: string = ''): HTMLElement {
  const element = document.createElement('td');
  element.setAttribute('id', generateRandomUUID());
  element.setAttribute('ws-tag', 'COLUMN');

  if (childTag !== '') {
    element.setAttribute(
      'element-id',
      generateRandomUUID().replace(/-/gm, '_')
    );
    element.setAttribute('element-ws-tag', childTag);
  }

  attachDragEvents(element, {
    dragover: handleCellDragOver,
    dragleave: handleCellDragLeave,
    drop: handleCellDrop,
  });

  return element;
}
