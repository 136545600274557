export type CallBack = (e: DragEvent) => void;

export type DragData = {
  source: EditorDragSource;
  html?: string;
  id: string;
  tag?: string;
};

export enum EditorDragSource {
  SIDEBAR = 'sidebar',
  TABLE = 'table',
}

export enum ElementTag {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  COMPANY_LOGO = 'COMPANY_LOGO',
  EMPLOYEE_PHOTO = 'EMPLOYEE_PHOTO',
  ICON = 'ICON',
  SOCIAL_ICON = 'SOCIAL_ICON',
  SOCIAL_ICONS = 'SOCIAL_ICONS',
  FACEBOOK_ICON = 'FACEBOOK_ICON',
  INSTAGRAM_ICON = 'INSTAGRAM_ICON',
  LINKEDIN_ICON = 'LINKEDIN_ICON',
  TIKTOK_ICON = 'TIKTOK_ICON',
  X_ICON = 'X_ICON',
  YOUTUBE_ICON = 'YOUTUBE_ICON',
  DETAIL = 'DETAIL',
  DETAILS = 'DETAILS',
  NAME = 'NAME',
  PRONOUNS = 'PRONOUNS',
  COMPANY = 'COMPANY',
  TITLE = 'TITLE',
  EMAIL = 'EMAIL',
  ADDRESS = 'ADDRESS',
  WEBSITE = 'WEBSITE',
  PHONE = 'PHONE',
  FAX = 'FAX',
  MOBILE = 'MOBILE',
  EXTENSION = 'EXTENSION',
  DIVIDER = 'DIVIDER',
  QUOTE = 'QUOTE',
  HTML = 'HTML',
  PROMOTION = 'PROMOTION',
  GREEN_FOOTER = 'GREEN_FOOTER',
  DOWNLOAD_BUTTONS = 'DOWNLOAD_BUTTONS',
  DISCLAIMER = 'DISCLAIMER',
  SIGNOFF = 'SIGNOFF',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  IMAGE_GALLERY = 'IMAGE_GALLERY',
  INSTAGRAM_GALLERY = 'INSTAGRAM_GALLERY',
  BUTTON = 'BUTTON',
  ANIMATED_IMAGE = 'ANIMATED_IMAGE',
}
