import { ElementBox } from './side-panel-types';

export const elementBoxes: ElementBox[] = [
  {
    id: 'textItem',
    tag: 'TEXT',
    icon: 'fa-text fa-light',
    label: 'Text',
    html: '<p><span>Text Element</span></p>',
  },
  {
    id: 'imageItem',
    tag: 'IMAGE',
    icon: 'fa-image fa-light',
    label: 'Image',
    html: '<p><img draggable="false" src="https://img.freepik.com/premium-vector/avatar-profile-icon-flat-style-male-user-profile-vector-illustration-isolated-background-man-profile-sign-business-concept_157943-38764.jpg?semt=ais_hybrid" alt="Image Element" style="width: 50px;"></p>',
  },
];
