import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';
import { auth4Sdks } from '@mfl/platform-shell';
import {
  renderApiSdk,
  manageTemplateApiSdk,
} from '@msl/signature-editor-gateway-sdk';

import mfe from './layout-editor-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'layout-editor',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: async (app) => {
    useQuasarPlugin(app);

    await manageTemplateApiSdk.init(auth4Sdks());
    await renderApiSdk.init(auth4Sdks());
  },
});

export { bootstrap, mount, unmount };
