import { EditorViewMode } from '../../layout-editor-types';
import { focusedElement } from '../../shared/reactives';

import { currentTab, style, data } from '../../shared/reactives';
import {
  handleCellDragLeave,
  handleCellDragOver,
  handleCellDrop,
  handleDragStart,
} from '../../shared/event-handlers';
import { attachDragEvents, attachDragEventToElement } from './drag-utils';

export function handleElementClick(e: Event) {
  const currentTarget = e.currentTarget as HTMLElement;
  if (!currentTarget) return;

  // If clicking the same element that's already focused, blur it
  if (focusedElement.value === currentTarget) {
    currentTarget.classList.remove('focused');
    focusedElement.value = null;
    currentTab.value = EditorViewMode.ELEMENTS;
    return;
  }

  // Remove focus from previously focused element
  if (focusedElement.value) {
    focusedElement.value.classList.remove('focused');
  }

  // Focus new element
  currentTarget.classList.add('focused');
  focusedElement.value = currentTarget;
  currentTab.value = EditorViewMode.EDIT;

  const styleId =
    focusedElement.value?.parentElement?.getAttribute('element-style-id') ?? '';
  const dataId =
    focusedElement.value?.parentElement?.getAttribute('element-data-id') ?? '';

  if (styleId && !style[styleId]) {
    style[styleId] = {};
  }

  if (dataId && !data[dataId]) {
    data[dataId] = {};
  }
}

export function setHtmlAndReattachEvents(html: string) {
  const canvas = document.getElementById('canvas');

  if (canvas) canvas.setHTMLUnsafe(html);

  const tds = document.querySelectorAll('[ws-tag="COLUMN"]');
  tds.forEach((td) => {
    attachDragEvents(td as HTMLElement, {
      dragover: handleCellDragOver,
      dragleave: handleCellDragLeave,
      drop: handleCellDrop,
    });

    if (td.getAttribute('element-id')) {
      const child = td.children[0] as HTMLElement;
      attachDragEventToElement(child, handleDragStart);
      child.setAttribute('draggable', 'true');
      child.addEventListener('click', handleElementClick);
    }

    td.querySelectorAll('img').forEach((img) =>
      img.setAttribute('draggable', 'false')
    );
  });
}
