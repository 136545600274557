<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import strings from '../../layout-editor.strings';
import { DragData, EditorDragSource } from '../../shared/types';
import {
  generateColumnElement,
  generateRowElement,
  generateTableElement,
} from './element-generators';
import { createDroppedElementFromHTML } from './drag-utils';
import { addChildAttributes } from './element-utils';
import { style, data } from '../../shared/reactives';
import { debounce } from '../../shared/utils';
import { updateCanvas } from '../../shared/db';

const renderByIdInProgress = ref(false);
const debouncedUpdateCanvas = debounce(updateCanvas, 200);

watch(
  [renderByIdInProgress, style, data],
  async (_newVals, [oldRenderByIdInProgress]) => {
    if (renderByIdInProgress.value || oldRenderByIdInProgress === true) return;
    await debouncedUpdateCanvas();
  },
  { deep: true }
);

onMounted(() => {
  const canvas = document.getElementById('canvas');
  if (!(canvas instanceof HTMLElement)) return;

  // Allow drops on the canvas.
  canvas.addEventListener('dragover', (e: DragEvent) => {
    e.preventDefault();
  });

  // When dropping on the canvas background (only if the table isn’t created yet).
  canvas.addEventListener('drop', (e: DragEvent) => {
    e.preventDefault();

    const dataString = e.dataTransfer?.getData('application/json');
    if (!e.dataTransfer || !dataString) return;

    let data: DragData;
    try {
      data = JSON.parse(dataString);
    } catch {
      console.error('Invalid JSON data');
      return; // Exit if JSON is malformed
    }

    // Ensure the drop target is an HTML element
    let table: HTMLElement | null = canvas.querySelector('table');
    if (table) return;

    // Place the element into the new cell.
    if (data.source === EditorDragSource.SIDEBAR && data.html) {
      const newEl = createDroppedElementFromHTML(data.html);
      if (newEl) {
        // Create a table with one row and one cell.
        table = generateTableElement();
        const tr = generateRowElement();
        const td = generateColumnElement(data.tag ?? '');

        td.appendChild(newEl);
        tr.appendChild(td);
        table.appendChild(tr);
        canvas.appendChild(table);
        addChildAttributes(newEl);
      }
    }
  });
});
</script>

<template>
  <div class="layout-preview">
    <div class="layout-preview-container">
      <div class="layout-preview-container-box">
        <div class="layout-preview-container-box-label">New Message</div>
        <div class="layout-preview-container-box-wrap">
          <div class="layout-preview-container-box-wrap-details">
            <div class="layout-preview-container-box-wrap-details-to">To</div>
            <div class="layout-preview-container-box-wrap-details-subject">
              Subject
            </div>
          </div>
          <div class="layout-preview-container-box-body">
            <div
              id="canvas"
              class="layout-preview-container-box-body-canvas"
              :data-empty-canvas="strings.emptyCanvas"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* Table and cell styling */
:deep(table) {
  border-collapse: collapse;
}

:deep(td) {
  padding: 0;
  margin: 0;
  position: relative;
  border: 1px solid white;
}

/* Draggable items */
.draggable {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(var(--color-gray-100));
  background: white;
  cursor: grab;
  height: 100px;
}

/* When an element is inside a cell, change the cursor */
:deep(td) .draggable {
  cursor: move;
}

/* Highlight left/right drop zones */
:deep(.drop-left) {
  border-left: 10px solid blue;
}

:deep(.drop-right) {
  border-right: 10px solid blue;
}

:deep(.drop-top) {
  border-top: 10px solid blue;
}

:deep(.drop-bottom) {
  border-bottom: 10px solid blue;
}

.layout-preview {
  width: 100%;
  padding-top: 130px;
  background: rgb(var(--color-gray-100));

  &-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 70px 100px 100px;
    margin: 0 auto;
    height: calc(100vh - 183px);

    &-box {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 6px;
      min-height: 250px;
      width: 640px;
      max-height: 600px;
      padding: 0;
      margin: 0 auto;

      &-label {
        border-radius: 6px 6px 0 0;
        padding: 10px;
        font-size: 9px;
        font-weight: 700;
        color: rgb(var(--color-gray-400));
        background-color: rgb(var(--color-primary-300));
      }

      &-wrap {
        display: flex;
        flex-direction: column;
        border-radius: 0 0 6px 6px;
        padding: 0 20px 40px;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        box-shadow: var(--box-shadow);
        will-change: height;
        background-color: #fff;

        &-details {
          margin-bottom: 10px;
          font-size: 12px;
          color: rgb(var(--color-gray-300));

          &-to,
          &-subject {
            padding: 10px 20px;
            border-bottom: 1px solid rgb(var(--color-gray-200));
          }
        }
      }

      &-body {
        display: flex;
        padding: 10px 0 0;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #9f9f9f;
        }
        &::-webkit-scrollbar-track {
          background-color: #ccc;
        }

        &-canvas {
          min-height: 160px;
          flex: 1;
          position: relative;

          :deep(.focused) {
            outline: 2px solid rgb(var(--color-primary));
          }

          &:empty:before {
            height: 100%;
            content: attr(data-empty-canvas);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            color: rgb(var(--color-gray-400));
          }
        }
      }
    }
  }
}
</style>
