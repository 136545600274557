import { ToastStatus } from '@mfl/common-components';
import {
  manageTemplateApiSdk,
  renderApiSdk,
} from '@msl/signature-editor-gateway-sdk';

import { focusedElement, data, style } from './reactives';
import { setHtmlAndReattachEvents } from '../components/layout-preview/element-events';

export async function updateCanvas() {
  const focusedDataId =
    focusedElement.value?.parentElement?.getAttribute('element-data-id');

  const canvas = document.getElementById('canvas');
  const res = await renderApiSdk.renderByHtml({
    html: canvas?.innerHTML,
    payload: {},
    style: style.value,
    data: data.value,
  });

  setHtmlAndReattachEvents(res.html || '');

  // Restore focus if there was a focused element
  if (focusedDataId) {
    const parentElement = document.querySelector(
      `[element-data-id="${focusedDataId}"]`
    );
    const elementToFocus = parentElement?.children[0] as HTMLElement;

    if (elementToFocus) {
      elementToFocus.classList.add('focused');
      focusedElement.value = elementToFocus;
    }
  }
}

export async function saveSignature(
  signatureName: string,
  signatureId: string
): Promise<ToastStatus> {
  try {
    const canvas = document.getElementById('canvas');
    await manageTemplateApiSdk[signatureId ? 'update' : 'create']({
      id: signatureId || undefined,
      html: canvas?.innerHTML,
      style: style.value,
      data: data.value,
      name: signatureName,
    });
  } catch (error) {
    console.error('error', error);
    return ToastStatus.Error;
  }

  return ToastStatus.Completed;
}
